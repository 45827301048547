import React, { FC, FormEvent, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { ClassNameMap } from '@mui/styles';
import {
  UserAuthPreferencesData,
  ensureMinimumValueOfOne,
  getAuthMessageColor,
} from '../SecuritySettings.component';
import requestClient from '../../../../../utilities/requestClient';
import TokenService from '../../../../../services/token-service';

const useStyles = makeStyles(() => ({
  settingsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.4rem',
  },
  settingsTitle: {
    fontSize: '2rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
    marginBottom: '1rem',
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
  },
  settingsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  settingsItemTitle: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  settingsItemDescription: {
    fontSize: '1.2rem',
    color: mainColors.mainBlue,
    fontWeight: 400,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  settingsItemValueContainer: {
    display: 'flex',
    gap: '1rem',
    color: mainColors.mainBlue,
    fontWeight: 600,
    alignItems: 'center',
    fontSize: '1.6rem',
  },
  settingsItemValue: {
    all: 'unset',
    fontSize: '1.6rem',
    fontWeight: 400,
    padding: '0.5rem 1rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '10rem',
  },
  submitChangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '3rem',
  },
  submitEditButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.5rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  submitEditButton_inactive: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.5rem',
    backgroundColor: greys.grey300,
    color: 'white',
    userSelect: 'none',
  },
}));

// this is where the data will be passed in from the parent component
// be sure to also update the "No settings to display" message to include any changes to these props
interface Props {
  maximum_failed_login_attempts: UserAuthPreferencesData | undefined;
  account_frozen_minutes: UserAuthPreferencesData | undefined;
  two_factor_auth: UserAuthPreferencesData | undefined;
  reloadSettings: () => void;
}

const LoginSettings: FC<Props> = ({
  maximum_failed_login_attempts,
  account_frozen_minutes,
  two_factor_auth,
  reloadSettings,
}) => {
  const classes: ClassNameMap<string> = useStyles();

  const [maximumFailedLoginAttempts, setMaximumFailedLoginAttempts] =
    React.useState<number>(
      maximum_failed_login_attempts
        ? (maximum_failed_login_attempts.attribute_value as unknown as number)
        : 10,
    );
  const [accountFrozenMinutes, setAccountFrozenMinutes] =
    React.useState<number>(
      account_frozen_minutes
        ? (account_frozen_minutes.attribute_value as unknown as number)
        : 10,
    );
  const [twoFactorAuth, setTwoFactorAuth] = React.useState<boolean>(
    two_factor_auth
      ? (two_factor_auth.attribute_value as unknown as boolean)
      : false,
  );

  useEffect(() => {
    if (
      maximum_failed_login_attempts &&
      (maximum_failed_login_attempts.attribute_value as unknown as number) !==
        maximumFailedLoginAttempts
    ) {
      setMaximumFailedLoginAttempts(
        maximum_failed_login_attempts.attribute_value as unknown as number,
      );
    }
    if (
      account_frozen_minutes &&
      (account_frozen_minutes.attribute_value as unknown as number) !==
        accountFrozenMinutes
    ) {
      setAccountFrozenMinutes(
        account_frozen_minutes.attribute_value as unknown as number,
      );
    }
    if (
      two_factor_auth &&
      (two_factor_auth.attribute_value as unknown as boolean) !== twoFactorAuth
    ) {
      setTwoFactorAuth(two_factor_auth.attribute_value as unknown as boolean);
    }
  }, [maximum_failed_login_attempts, account_frozen_minutes]);

  const [
    maximumFailedLoginAttemptsRequestStatus,
    setMaximumFailedLoginAttemptsRequestStatus,
  ] = React.useState<string>('idle');
  const [
    maximumFailedLoginAttemptsRequestMessage,
    setMaximumFailedLoginAttemptsRequestMessage,
  ] = React.useState<string>('');

  const [
    accountFrozenMinutesRequestStatus,
    setAccountFrozenMinutesRequestStatus,
  ] = React.useState<string>('idle');
  const [
    accountFrozenMinutesRequestMessage,
    setAccountFrozenMinutesRequestMessage,
  ] = React.useState<string>('');

  const [twoFactorAuthRequestStatus, setTwoFactorAuthRequestStatus] =
    React.useState<string>('idle');
  const [twoFactorAuthRequestMessage, setTwoFactorAuthRequestMessage] =
    React.useState<string>('');

  const client = requestClient();

  const handleChangeMaximumFailedLoginAttempts = (
    e: FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    setMaximumFailedLoginAttemptsRequestStatus('loading');
    setMaximumFailedLoginAttemptsRequestMessage(
      'Updating maximum failed login attempts...',
    );
    client
      .put(
        'raptor/settings/auth_preference/attributes',
        {
          attribute_type: 'auth_preference_attributes',
          attribute_name: 'maximum_failed_login_attempts',
          attributes: {
            attribute_value: maximumFailedLoginAttempts,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setMaximumFailedLoginAttemptsRequestStatus('success');
          setMaximumFailedLoginAttemptsRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setMaximumFailedLoginAttemptsRequestStatus('fail');
          setMaximumFailedLoginAttemptsRequestMessage(
            'Unknown Error. Please contact RiskSystem support.',
          );
        }
      })
      .catch(() => {
        setMaximumFailedLoginAttemptsRequestStatus('fail');
        setMaximumFailedLoginAttemptsRequestMessage(
          'Unknown Error. Please contact RiskSystem support.',
        );
      })
      .finally(() => {
        setTimeout(() => {
          setMaximumFailedLoginAttemptsRequestStatus('idle');
          setMaximumFailedLoginAttemptsRequestMessage('');
        }, 5000);
      });
    setTimeout(() => {
      setMaximumFailedLoginAttemptsRequestStatus('success');
      setMaximumFailedLoginAttemptsRequestMessage('Updated successfully.');
    }, 1000);
  };

  const handleChangeAccountFrozenMinutes = (
    e: FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    setAccountFrozenMinutesRequestStatus('loading');
    setAccountFrozenMinutesRequestMessage(
      'Updating account frozen duration...',
    );
    client
      .put(
        'raptor/settings/auth_preference/attributes',
        {
          attribute_type: 'auth_preference_attributes',
          attribute_name: 'account_frozen_minutes',
          attributes: {
            attribute_value: accountFrozenMinutes,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setAccountFrozenMinutesRequestStatus('success');
          setAccountFrozenMinutesRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setAccountFrozenMinutesRequestStatus('fail');
          setAccountFrozenMinutesRequestMessage(
            'Unknown Error. Please contact RiskSystem support.',
          );
        }
      })
      .catch(() => {
        setAccountFrozenMinutesRequestStatus('fail');
        setAccountFrozenMinutesRequestMessage(
          'Unknown Error. Please contact RiskSystem support.',
        );
      })
      .finally(() => {
        setTimeout(() => {
          setAccountFrozenMinutesRequestStatus('idle');
          setAccountFrozenMinutesRequestMessage('');
        }, 5000);
      });
  };

  const handleChangeTwoFactorAuth = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setTwoFactorAuthRequestStatus('loading');
    setTwoFactorAuthRequestMessage('Updating two factor authentication...');
    client
      .put(
        'raptor/settings/auth_preference/attributes',
        {
          attribute_type: 'auth_preference_attributes',
          attribute_name: '2fa_user_email',
          attributes: {
            attribute_value: twoFactorAuth,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setTwoFactorAuthRequestStatus('success');
          setTwoFactorAuthRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setTwoFactorAuthRequestStatus('fail');
          setTwoFactorAuthRequestMessage(
            'Unknown Error. Please contact RiskSystem support.',
          );
        }
      })
      .catch(() => {
        setTwoFactorAuthRequestStatus('fail');
        setTwoFactorAuthRequestMessage(
          'Unknown Error. Please contact RiskSystem support.',
        );
      })
      .finally(() => {
        setTimeout(() => {
          setTwoFactorAuthRequestStatus('idle');
          setTwoFactorAuthRequestMessage('');
        }, 5000);
      });
  };

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.settingsTitle}>Login Settings</div>
      {!maximum_failed_login_attempts && !account_frozen_minutes ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemDescription}>
              No settings to display
            </div>
          </div>
        </div>
      ) : null}
      {maximum_failed_login_attempts ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Maximum Failed Login Attempts
            </div>
            <div className={classes.settingsItemDescription}>
              The number of times a user can fail to login before their account
              is locked. This is a security measure to prevent brute force
              attacks.
            </div>
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeMaximumFailedLoginAttempts(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="number"
                  min={0}
                  max={365}
                  step={1}
                  value={maximumFailedLoginAttempts}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setMaximumFailedLoginAttempts(
                      ensureMinimumValueOfOne(Number(e.target.value)),
                    )
                  }
                  className={classes.settingsItemValue}
                />
                Attempts
              </div>
              <div className={classes.submitChangeContainer}>
                {(maximum_failed_login_attempts.attribute_value as unknown as number) !==
                maximumFailedLoginAttempts ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {maximumFailedLoginAttemptsRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(
                        maximumFailedLoginAttemptsRequestStatus,
                      ),
                    }}
                  >
                    <h3>{maximumFailedLoginAttemptsRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {account_frozen_minutes ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Account Frozen Duration
            </div>
            <div className={classes.settingsItemDescription}>
              The time in minutes that a user's account will be frozen for after
              exceeding the maximum failed login attempts. This is to prevent
              brute force attacks. We recommend atleast 2 minutes.
            </div>
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeAccountFrozenMinutes(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="number"
                  min={0}
                  max={365}
                  step={1}
                  value={accountFrozenMinutes}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAccountFrozenMinutes(
                      ensureMinimumValueOfOne(Number(e.target.value)),
                    )
                  }
                  className={classes.settingsItemValue}
                />
                Minutes
              </div>
              <div className={classes.submitChangeContainer}>
                {(account_frozen_minutes.attribute_value as unknown as number) !==
                accountFrozenMinutes ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {accountFrozenMinutesRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(
                        accountFrozenMinutesRequestStatus,
                      ),
                    }}
                  >
                    <h3>{accountFrozenMinutesRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {two_factor_auth ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Two Factor Authentication (2FA)
            </div>
            <div className={classes.settingsItemDescription}>
              An extra layer of security that requires a verification code sent
              to your email, in addition to your password.
            </div>
            <div className={classes.settingsItemDescription}>
              This is an organisation wide setting. If you enable this, all
              users will be required to use 2FA.
            </div>
            {/* add a toggle here to turn 2FA on / off */}
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeTwoFactorAuth(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="checkbox"
                  checked={twoFactorAuth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTwoFactorAuth(e.target.checked)
                  }
                />
                Enable 2FA Email Verification
              </div>
              <div className={classes.submitChangeContainer}>
                {(two_factor_auth?.attribute_value as unknown as boolean) !==
                twoFactorAuth ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {twoFactorAuthRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(twoFactorAuthRequestStatus),
                    }}
                  >
                    <h3>{twoFactorAuthRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LoginSettings;
