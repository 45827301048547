import React, { FC, FormEvent, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { ClassNameMap } from '@mui/styles';
import {
  UserAuthPreferencesData,
  ensureMinimumValueOfOne,
  getAuthMessageColor,
} from '../SecuritySettings.component';
import requestClient from '../../../../../utilities/requestClient';
import TokenService from '../../../../../services/token-service';

const useStyles = makeStyles(() => ({
  settingsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.4rem',
  },
  settingsTitle: {
    fontSize: '2rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
    marginBottom: '1rem',
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
  },
  settingsItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  settingsItemTitle: {
    fontSize: '1.5rem',
    borderBottom: `1px solid ${mainColors.mainBlue}`,
    width: 'fit-content',
    color: mainColors.mainBlue,
    fontWeight: 600,
  },
  settingsItemDescription: {
    fontSize: '1.2rem',
    color: mainColors.mainBlue,
    fontWeight: 400,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  settingsItemValueContainer: {
    display: 'flex',
    gap: '1rem',
    color: mainColors.mainBlue,
    fontWeight: 600,
    alignItems: 'center',
    fontSize: '1.6rem',
  },
  settingsItemValue: {
    all: 'unset',
    fontSize: '1.6rem',
    fontWeight: 400,
    padding: '0.5rem 1rem',
    color: mainColors.mainBlue,
    border: `1px solid ${mainColors.mainBlue}`,
    width: '10rem',
  },
  submitChangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '3rem',
  },
  submitEditButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.5rem',
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_slightlyDarker,
    },
  },
  submitEditButton_inactive: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    fontSize: '1.5rem',
    backgroundColor: greys.grey300,
    color: 'white',
    userSelect: 'none',
  },
}));

// this is where the data will be passed in from the parent component
// be sure to also update the "No settings to display" message to include any changes to these props
interface Props {
  password_update_frequency_days: UserAuthPreferencesData | undefined;
  reloadSettings: () => void;
}

const PasswordSettings: FC<Props> = ({
  password_update_frequency_days,
  reloadSettings,
}) => {
  const classes: ClassNameMap<string> = useStyles();

  const [resetFrequency, setResetFrequency] = React.useState<number>(
    password_update_frequency_days
      ? (password_update_frequency_days.attribute_value as unknown as number)
      : 90,
  );

  useEffect(() => {
    if (
      password_update_frequency_days &&
      (password_update_frequency_days.attribute_value as unknown as number) !==
        resetFrequency
    ) {
      setResetFrequency(
        password_update_frequency_days.attribute_value as unknown as number,
      );
    }
  }, [password_update_frequency_days]);

  const [resetFrequencyRequestStatus, setResetFrequencyRequestStatus] =
    React.useState<string>('idle');
  const [resetFrequencyRequestMessage, setResetFrequencyRequestMessage] =
    React.useState<string>('');

  const client = requestClient();

  const handleChangeResetFrequency = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setResetFrequencyRequestStatus('loading');
    setResetFrequencyRequestMessage('Updating password reset frequency...');
    client
      .put(
        'raptor/settings/auth_preference/attributes',
        {
          attribute_type: 'auth_preference_attributes',
          attribute_name: 'password_update_frequency_days',
          attributes: {
            attribute_value: resetFrequency,
          },
        },
        {
          headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          setResetFrequencyRequestStatus('success');
          setResetFrequencyRequestMessage('Updated successfully.');
          reloadSettings();
        } else {
          setResetFrequencyRequestStatus('fail');
          setResetFrequencyRequestMessage(
            'Unknown Error. Please contact RiskSystem support.',
          );
        }
      })
      .catch(() => {
        setResetFrequencyRequestStatus('fail');
        setResetFrequencyRequestMessage(
          'Unknown Error. Please contact RiskSystem support.',
        );
      })
      .finally(() => {
        setTimeout(() => {
          setResetFrequencyRequestStatus('idle');
          setResetFrequencyRequestMessage('');
        }, 5000);
      });
  };

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.settingsTitle}>Password Settings</div>
      {!password_update_frequency_days ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemDescription}>
              No settings to display
            </div>
          </div>
        </div>
      ) : null}
      {password_update_frequency_days ? (
        <div className={classes.settingsSection}>
          <div className={classes.settingsItem}>
            <div className={classes.settingsItemTitle}>
              Password Update Frequency
            </div>
            <div className={classes.settingsItemDescription}>
              This is the frequency at which users will be suggested to reset
              their passwords. Security best practices recommend that passwords
              be reset at least every 90 days.
            </div>
            <form
              className={classes.formContainer}
              onSubmit={(e: FormEvent<HTMLFormElement>) =>
                handleChangeResetFrequency(e)
              }
            >
              <div className={classes.settingsItemValueContainer}>
                <input
                  type="number"
                  min={0}
                  max={365}
                  step={1}
                  value={resetFrequency}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setResetFrequency(
                      ensureMinimumValueOfOne(Number(e.target.value)),
                    )
                  }
                  className={classes.settingsItemValue}
                />
                Days
              </div>
              <div className={classes.submitChangeContainer}>
                {(password_update_frequency_days.attribute_value as unknown as number) !==
                resetFrequency ? (
                  <button type="submit" className={classes.submitEditButton}>
                    Submit Change
                  </button>
                ) : (
                  <div className={classes.submitEditButton_inactive}>
                    Submit Change
                  </div>
                )}
                {resetFrequencyRequestStatus != 'idle' ? (
                  <div
                    style={{
                      color: getAuthMessageColor(resetFrequencyRequestStatus),
                    }}
                  >
                    <h3>{resetFrequencyRequestMessage}</h3>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PasswordSettings;
