import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import requestClient from '../../../../../utilities/requestClient';
import { Modal, Tooltip } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import TokenService from '../../../../../services/token-service';

interface Props {
  user: any;
  reloadUsers: () => void;
}

const useStyles = makeStyles(() => ({
  deleteUserButton: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    border: `1px solid ${mainColors.Fail}`,
    color: mainColors.Fail,
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '1.6rem',
    },
    '&:hover': {
      backgroundColor: mainColors.Fail,
      color: 'white',
    },
    '&:active': {
      backgroundColor: mainColors.Fail_darker,
      color: 'white',
    },
  },
  modalBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentContainer: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '0.4rem',
    border: `1px solid ${mainColors.Fail}`,
    width: '50rem',
  },
  modalTitle: {
    fontSize: '3rem',
    fontWeight: 600,
    color: mainColors.Fail,
  },
  modalContent: {
    fontSize: '2rem',
    fontWeight: 400,
    color: greys.grey700,
    display: 'flex',
    gap: '1rem',
    padding: '1rem 0',
  },
  username: {
    fontWeight: 600,
    color: greys.grey900,
  },
  modalActionContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  cancelButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 2rem',
    borderRadius: '0.4rem',
    fontSize: '2rem',
    fontWeight: 400,
    backgroundColor: mainColors.mainBlue,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.mainBlue_lighter,
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_Darker,
    },
  },
  deleteButton: {
    all: 'unset',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 2rem',
    borderRadius: '0.4rem',
    fontSize: '2rem',
    fontWeight: 400,
    backgroundColor: mainColors.Fail,
    color: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: mainColors.Fail_darker,
    },
    '&:active': {
      backgroundColor: mainColors.Fail_even_darker,
    },
  },
  requestMessage: {
    fontSize: '1.4rem',
    fontWeight: 500,
    marginTop: '1rem',
  },
}));

const getAuthMessageColor = (authStatus: string): string => {
  switch (authStatus) {
    case 'success':
      return mainColors.Pass;
    case 'fail':
      return mainColors.Fail;
    default:
      return greys.grey600;
  }
};

const DeleteUserConfirmation: FC<Props> = ({ user, reloadUsers }) => {
  const classes = useStyles();
  const client = requestClient();

  const [open, setOpen] = React.useState(false);

  const [requestStatus, setRequestStatus] = React.useState<string>('');
  const [requestMessage, setRequestMessage] = React.useState<string>('');

  const handleDeleteUser = () => {
    setRequestStatus('loading');
    setRequestMessage('Deleting user...');
    client
      .delete('raptor/delete_user/' + user.user_id, {
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then(() => {
        setRequestStatus('success');
        setRequestMessage('User deleted');
        reloadUsers();
        setOpen(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setRequestStatus('fail');
          setRequestMessage('You are not authorized to perform this action.');
        } else {
          setRequestStatus('fail');
          setRequestMessage('Error deleting user');
        }
      })
      .finally(() => {
        setTimeout(() => {
          setRequestStatus('');
          setRequestMessage('');
        }, 3000);
      });
  };

  return (
    <>
      <Tooltip title="Delete Account">
        <div onClick={() => setOpen(true)} className={classes.deleteUserButton}>
          <DeleteOutline />
        </div>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modalBackground}
      >
        <div className={classes.modalContentContainer}>
          <div className={classes.modalTitle}>Are you sure?</div>
          <div className={classes.modalContent}>
            <div>You are about to delete the user: </div>
            <div className={classes.username}>{user.user_name}</div>
          </div>
          <div className={classes.modalActionContainer}>
            <div
              className={classes.cancelButton}
              onClick={() => setOpen(false)}
            >
              Cancel
            </div>
            <div
              className={classes.deleteButton}
              onClick={() => handleDeleteUser()}
            >
              Delete
            </div>
          </div>
          <div
            className={classes.requestMessage}
            style={{
              color: getAuthMessageColor(requestStatus),
            }}
          >
            {requestMessage}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteUserConfirmation;
